import RequestService from "../utils/request";

import { ISystemCluster } from "../interfaces/system.interface";
import { getRandomInt } from "../utils/util";

class SystemService {
  async getClusterInfo(): Promise<ISystemCluster[]> {
    // const devices = await RequestService.get<ISystemCluster[]>("/sys");
    return [
      {
        id: "p2-cluster-0",
        cpu: getRandomInt(5, 10) / 100,
        ram: getRandomInt(78, 88) / 100,
        nrOfServices: 13
      }
    ]
  }
}

const systemService = new SystemService();
export default systemService;
