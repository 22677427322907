import * as echarts from "echarts";
import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  ConfigProvider,
  Row,
  Select,
  Spin,
  Statistic,
  Tooltip,
  Typography,
  message,
  theme,
} from "antd";
import { useTranslation } from "react-i18next";

import "../styles/dashboard.scss";

import deviceTwinService from "../services/device.service";
import databaseInfoService from "../services/database.service";
import SystemService from "../services/system.service";

import { DeviceTwinStatus, IDeviceTwin } from "../interfaces/device.interface";
import { ITelemtryStatistics } from "../interfaces/telemetry.interface";
import { convertByteToReadableVal } from "../utils/util";
import { TelemetryList, TotalDBSize } from "../utils/constants";
import { ISystemCluster } from "../interfaces/system.interface";
import { catchError } from "rxjs";
import { set } from "lodash";

interface IDeviceSummary {
  online: IDeviceTwin[];
  warn: IDeviceTwin[];
  error: IDeviceTwin[];
  offline: IDeviceTwin[];
}

const DashboardPage: React.FC = () => {
  const refreshIntervalMs = 10000;

  const { darkAlgorithm } = theme;
  const { Text } = Typography;
  const { t } = useTranslation();

  const [devices, setDevices] = useState<IDeviceSummary | undefined>(undefined);
  const [databaseInfo, setDatabaseInfo] = useState<ITelemtryStatistics[]>([]);
  const [aksInfo, setAKSInfo] = useState<ISystemCluster[]>([]);
  const [selectedAKS, setSelectedAKS] = useState<ISystemCluster | undefined>(
    undefined
  );
  const [aiModuleList, setAIModuleList] = useState<any[]>([]);

  const [databaseStoragePieChart, setDatabaseStoragePieChart] = useState<
    echarts.ECharts | undefined
  >(undefined);
  const [databaseDtoBarChart, setDatabaseDtoBarChart] = useState<
    echarts.ECharts | undefined
  >(undefined);
  const [cpuGaugeChart, setCPUGaugeChart] = useState<
    echarts.ECharts | undefined
  >(undefined);
  const [ramGaugeChart, setRAMGaugeChart] = useState<
    echarts.ECharts | undefined
  >(undefined);

  const refreshDeviceList = () => {
    deviceTwinService
      .getAllDevices()
      .then((result) => {
        let online: IDeviceTwin[] = [];
        let offline: IDeviceTwin[] = [];
        let warn: IDeviceTwin[] = [];
        let error: IDeviceTwin[] = [];

        result.forEach((item) => {
          if (item.status === DeviceTwinStatus.Error) {
            error.push(item);
          }
          if (item.status === DeviceTwinStatus.Warn) {
            warn.push(item);
          }
          if (item.status === DeviceTwinStatus.Online) {
            online.push(item);
          }
          if (item.status === DeviceTwinStatus.Offline) {
            offline.push(item);
          }
        });

        setDevices({
          online,
          offline,
          warn,
          error,
        });
      })
      .catch((err) => {
        console.error(err);
        message.error(t("error.network error"));
      });
  };

  const refreshDatabaseInfo = () => {
    databaseInfoService
      .getDatabaseStoragePercentages()
      .pipe(
        catchError((err) => {
          console.error(err);
          message.error(t("error.network error"));
          return [];
        })
      )
      .subscribe((result) => {
        let totalUsedBytes = 0;
        result.forEach((item) => {
          totalUsedBytes += parseFloat(item.totalSize);
        });
        const remainingSpace: ITelemtryStatistics = {
          tableName: t("label.freeSpace"),
          totalSize: `${TotalDBSize - totalUsedBytes}`,
          totalNr: 0,
          statistics: [],
        };
        result.splice(0, 0, remainingSpace);
        setDatabaseInfo(result);
      });
  };

  const refreshAKSInfo = () => {
    SystemService.getClusterInfo()
      .then((result) => {
        setAKSInfo(result);
        if (!selectedAKS) {
          setSelectedAKS(result[0]);
        }
      })
      .catch((err) => {
        console.error(err);
        message.error(t("error.network error"));
      });
  };

  const handleAKSChange = (value: string) => {
    setSelectedAKS(aksInfo.find((item) => item.id === value));
  };

  useEffect(() => {
    refreshDeviceList();
    refreshDatabaseInfo();
    refreshAKSInfo();
    const intervalId = setInterval(() => {
      refreshDeviceList();
      refreshDatabaseInfo();
      refreshAKSInfo();
    }, refreshIntervalMs);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (!document.getElementById("databaseStoragePieChart")) {
      console.info(`no element found`);
      return;
    }

    if (!databaseStoragePieChart) {
      const initChart = echarts.init(
        document.getElementById("databaseStoragePieChart")!,
        "dark"
      );
      if (databaseInfo.length === 0) {
        return;
      }
      const option = {
        backgroundColor: "",
        title: {
          text: t("label.storagePercentageChart"),
          left: "center",
        },
        tooltip: {
          trigger: "item",
          valueFormatter: (value: string) => convertByteToReadableVal(value),
        },
        legend: {
          show: true,
          type: "scroll",
          top: "30px",
          left: "left",
          orient: "vertical",
        },
        series: [
          {
            type: "pie",
            height: "100%",
            left: "center",
            width: 400,
            labelLine: {
              show: false,
            },
            label: {
              show: false,
              position: "center",
            },
            data: databaseInfo.map((dto) => {
              return {
                name: dto.tableName,
                value: dto.totalSize,
              };
            }),
          },
        ],
      };
      initChart.setOption(option);
      setDatabaseStoragePieChart(initChart);
    } else {
      const option = {
        backgroundColor: "",
        title: {
          text: t("label.storagePercentageChart"),
          left: "center",
        },
        tooltip: {
          trigger: "item",
          valueFormatter: (value: string) => convertByteToReadableVal(value),
        },
        legend: {
          show: true,
          type: "scroll",
          top: "30px",
          left: "left",
          orient: "vertical",
        },
        series: [
          {
            type: "pie",
            height: "100%",
            left: "center",
            width: 400,
            labelLine: {
              show: false,
            },
            label: {
              show: false,
              position: "center",
            },
            data: databaseInfo.map((dto) => {
              return {
                name: dto.tableName,
                value: dto.totalSize,
              };
            }),
          },
        ],
      };
      databaseStoragePieChart.setOption(option);
    }
  }, [databaseInfo, databaseStoragePieChart, t]);

  useEffect(() => {
    if (!document.getElementById("databaseDtoBarChart")) {
      console.info(`no element found`);
      return;
    }

    const deviceIdSet = new Set<string>();
    databaseInfo.forEach((item) => {
      if (TelemetryList.includes(item.tableName)) {
        const statistics = item.statistics;
        statistics.forEach((dto) => {
          const tid = dto.id;
          // check if it is related to project
          if (
            tid.split("-").length >= 6 &&
            tid.split("-")[0] === tid.split("-")[0].toUpperCase()
          ) {
            deviceIdSet.add(tid.split("-")[0]);
          } else {
            deviceIdSet.add(tid);
          }
        });
      }
    });
    const series: any[] = Array.from(deviceIdSet).map((item) => {
      return {
        name: item,
        type: "bar",
        stack: "total",
        emphasis: {
          focus: "series",
        },
        data: [],
      };
    });

    // For workaround now
    const rwsAmount = databaseInfo.find((item) =>
      item.tableName.includes("rainuse")
    );
    if (rwsAmount) {
      series.push({
        name: "RWS",
        type: "bar",
        stack: "total",
        emphasis: {
          focus: "series",
        },
        //"bio","current","pressure","turbidity","waterflow","waterlevel","watermeter","temperature"
        data: [
          rwsAmount.totalNr,
          0,
          rwsAmount.totalNr * 4,
          rwsAmount.totalNr * 3,
          rwsAmount.totalNr * 4,
          rwsAmount.totalNr * 5,
          rwsAmount.totalNr * 4,
          0,
        ],
      });
    }

    const gfsAmount = databaseInfo.find((item) =>
      item.tableName.includes("grundflush")
    );
    if (gfsAmount) {
      series.forEach((serie) => {
        if (serie.name === "GFS") {
          console.info(`check serie data ${JSON.stringify(serie.data)}`);
          //"bio","current","pressure","turbidity","waterflow","waterlevel","watermeter","temperature"
          serie.data = [
            0,
            gfsAmount.totalNr * 6,
            0,
            0,
            gfsAmount.totalNr * 6,
            0,
            gfsAmount.totalNr * 6,
            gfsAmount.totalNr,
          ];
        }
      });
    }

    TelemetryList.forEach((telemetry) => {
      const foundDBInfo = databaseInfo.find(
        (item) => item.tableName === telemetry
      );
      if (foundDBInfo) {
        const statistics = foundDBInfo.statistics;
        series.forEach((serie) => {
          const foundItems = statistics.filter((statistic) =>
            statistic.id.includes(serie.name)
          );
          if (foundItems.length !== 0) {
            let total = 0;
            foundItems.forEach((item) => (total += Number(item.count)));
            serie.data.push(total);
            // console.info(
            //   `In ${foundDBInfo.tableName}, data of ${serie.name} is ${total}`
            // );
          } else {
            serie.data.push(0);
            // console.info(
            //   `In ${foundDBInfo.tableName}, data of ${serie.name} is 0`
            // );
          }
        });
      }
    });

    if (!databaseDtoBarChart) {
      const initChart = echarts.init(
        document.getElementById("databaseDtoBarChart")!,
        "dark"
      );

      if (databaseInfo.length === 0) {
        return;
      }

      const option = {
        backgroundColor: "",
        title: {
          text: t("label.projectTelemetryAmountChart"),
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          show: true,
          type: "scroll",
          bottom: "0",
        },
        grid: {
          containLabel: true,
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: TelemetryList,
        },
        series: series,
      };
      initChart.setOption(option);
      setDatabaseDtoBarChart(initChart);
    } else {
      const option = {
        backgroundColor: "",
        title: {
          text: t("label.projectTelemetryAmountChart"),
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          show: true,
          type: "scroll",
          bottom: "0",
        },
        grid: {
          containLabel: true,
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: TelemetryList,
        },
        series: series,
      };
      databaseDtoBarChart.setOption(option);
    }
  }, [databaseInfo, databaseDtoBarChart, t]);

  useEffect(() => {
    if (!document.getElementById("cpuGaugeChart")) {
      console.info(`no element found`);
      return;
    }

    if (!cpuGaugeChart) {
      const initChart = echarts.init(
        document.getElementById("cpuGaugeChart")!,
        "dark"
      );

      const option = {
        backgroundColor: "",
        title: {
          text: t("label.cpuGaugeChart"),
          left: "center",
        },
        series: [
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            center: ["50%", "75%"],
            radius: "120%",
            min: 0,
            max: 1,
            splitNumber: 8,
            axisLine: {
              lineStyle: {
                width: 3,
                color: [
                  [0.5, "#7CFFB2"],
                  [0.8, "yellow"],
                  [1, "#FF6E76"],
                ],
              },
            },
            pointer: {
              icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
              length: "75%",
              width: 8,
              offsetCenter: [0, "-20%"],
              itemStyle: {
                color: "white",
              },
            },
            axisTick: {
              length: 6,
              lineStyle: {
                color: "auto",
                width: 1,
              },
            },
            axisLabel: {
              formatter: (value: any) => {
                return Math.round(value * 100) + " %";
              },
            },
            splitLine: {
              length: 20,
              lineStyle: {
                color: "auto",
                width: 2,
              },
            },
            detail: {
              fontSize: 24,
              offsetCenter: [0, "-5%"],
              valueAnimation: true,
              formatter: (value: any) => {
                return Math.round(value * 100) + " %";
              },
              color: "inherit",
            },
            data: aksInfo.map((item) => {
              return {
                value: item.cpu ?? -1,
              };
            }),
          },
        ],
      };
      initChart.setOption(option);
      setCPUGaugeChart(initChart);
    } else {
      const option = {
        backgroundColor: "",
        title: {
          text: t("label.projectTelemetryAmountChart"),
          left: "center",
        },
        series: [
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            center: ["50%", "75%"],
            radius: "120%",
            min: 0,
            max: 1,
            splitNumber: 8,
            axisLine: {
              lineStyle: {
                width: 3,
                color: [
                  [0.5, "#7CFFB2"],
                  [0.8, "yellow"],
                  [1, "#FF6E76"],
                ],
              },
            },
            pointer: {
              icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
              length: "75%",
              width: 8,
              offsetCenter: [0, "-20%"],
              itemStyle: {
                color: "white",
              },
            },
            axisTick: {
              length: 6,
              lineStyle: {
                color: "auto",
                width: 1,
              },
            },
            axisLabel: {
              formatter: (value: any) => {
                return Math.round(value * 100) + " %";
              },
            },
            splitLine: {
              length: 20,
              lineStyle: {
                color: "auto",
                width: 2,
              },
            },
            detail: {
              fontSize: 24,
              offsetCenter: [0, "-5%"],
              valueAnimation: true,
              formatter: (value: any) => {
                return Math.round(value * 100) + " %";
              },
              color: "inherit",
            },
            data: aksInfo.map((item) => {
              return {
                value: item.cpu ?? -1,
              };
            }),
          },
        ],
      };
      cpuGaugeChart.setOption(option);
    }
  }, [aksInfo, cpuGaugeChart, t]);

  useEffect(() => {
    if (!document.getElementById("ramGaugeChart")) {
      console.info(`no element found`);
      return;
    }

    if (!ramGaugeChart) {
      const initChart = echarts.init(
        document.getElementById("ramGaugeChart")!,
        "dark"
      );

      const option = {
        backgroundColor: "",
        title: {
          text: t("label.ramGaugeChart"),
          left: "center",
        },
        series: [
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            center: ["50%", "75%"],
            radius: "120%",
            min: 0,
            max: 1,
            splitNumber: 8,
            axisLine: {
              lineStyle: {
                width: 3,
                color: [
                  [0.7, "#7CFFB2"],
                  [0.9, "yellow"],
                  [1, "#FF6E76"],
                ],
              },
            },
            pointer: {
              icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
              length: "75%",
              width: 8,
              offsetCenter: [0, "-20%"],
              itemStyle: {
                color: "white",
              },
            },
            axisTick: {
              length: 6,
              lineStyle: {
                color: "auto",
                width: 1,
              },
            },
            axisLabel: {
              formatter: (value: any) => {
                return Math.round(value * 100) + " %";
              },
            },
            splitLine: {
              length: 20,
              lineStyle: {
                color: "auto",
                width: 2,
              },
            },
            detail: {
              fontSize: 24,
              offsetCenter: [0, "-5%"],
              valueAnimation: true,
              formatter: (value: any) => {
                return Math.round(value * 100) + " %";
              },
              color: "inherit",
            },
            data: aksInfo.map((item) => {
              return {
                value: item.ram ?? -1,
              };
            }),
          },
        ],
      };
      initChart.setOption(option);
      setRAMGaugeChart(initChart);
    } else {
      const option = {
        backgroundColor: "",
        title: {
          text: t("label.projectTelemetryAmountChart"),
          left: "center",
        },
        series: [
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            center: ["50%", "75%"],
            radius: "120%",
            min: 0,
            max: 1,
            splitNumber: 8,
            axisLine: {
              lineStyle: {
                width: 3,
                color: [
                  [0.7, "#7CFFB2"],
                  [0.9, "yellow"],
                  [1, "#FF6E76"],
                ],
              },
            },
            pointer: {
              icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
              length: "75%",
              width: 8,
              offsetCenter: [0, "-20%"],
              itemStyle: {
                color: "white",
              },
            },
            axisTick: {
              length: 6,
              lineStyle: {
                color: "auto",
                width: 1,
              },
            },
            axisLabel: {
              formatter: (value: any) => {
                return Math.round(value * 100) + " %";
              },
            },
            splitLine: {
              length: 20,
              lineStyle: {
                color: "auto",
                width: 2,
              },
            },
            detail: {
              fontSize: 24,
              offsetCenter: [0, "-5%"],
              valueAnimation: true,
              formatter: (value: any) => {
                return Math.round(value * 100) + " %";
              },
              color: "inherit",
            },
            data: aksInfo.map((item) => {
              return {
                value: item.ram ?? -1,
              };
            }),
          },
        ],
      };
      ramGaugeChart.setOption(option);
    }
  }, [aksInfo, ramGaugeChart, t]);

  return (
    <ConfigProvider
      theme={{
        algorithm: darkAlgorithm,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col className="gutter-row" span={16}>
          <Row gutter={[16, 16]}>
            <Col className="gutter-row" span={8}>
              <Card className="summaryCard" bordered={false}>
                <Text strong>{t("dashboard.card.totalDevice")}</Text>
                <Statistic
                  value={
                    devices
                      ? devices.online.length +
                        devices.offline.length +
                        devices.warn.length +
                        devices.error.length
                      : "--"
                  }
                />
              </Card>
            </Col>
            <Col className="gutter-row" span={4}>
              <Card className="summaryCard" bordered={false}>
                <Tooltip
                  title={
                    devices && devices.online.length !== 0 ? (
                      <span>
                        {devices.online.map((item) => `${item.name}\n`)}
                      </span>
                    ) : (
                      "--"
                    )
                  }
                >
                  <Text type="success" strong>
                    {t("dashboard.card.healthDevice")}
                  </Text>
                  <Statistic value={devices ? devices.online.length : "--"} />
                </Tooltip>
              </Card>
            </Col>
            <Col className="gutter-row" span={4}>
              <Card className="summaryCard" bordered={false}>
                <Tooltip
                  title={
                    devices && devices.warn.length !== 0 ? (
                      <span>
                        {devices.warn.map((item) => `${item.name}\n`)}
                      </span>
                    ) : (
                      "--"
                    )
                  }
                >
                  <Text type="warning" strong>
                    {t("dashboard.card.warningDevice")}
                  </Text>
                  <Statistic value={devices ? devices.warn.length : "--"} />
                </Tooltip>
              </Card>
            </Col>
            <Col className="gutter-row" span={4}>
              <Card className="summaryCard" bordered={false}>
                <Tooltip
                  title={
                    devices && devices.error.length !== 0 ? (
                      <span>
                        {devices.error.map((item) => `${item.name}\n`)}
                      </span>
                    ) : (
                      "--"
                    )
                  }
                >
                  <Text type="danger" strong>
                    {t("dashboard.card.brokenDevice")}
                  </Text>
                  <Statistic value={devices ? devices.error.length : "--"} />
                </Tooltip>
              </Card>
            </Col>
            <Col className="gutter-row" span={4}>
              <Card className="summaryCard" bordered={false}>
                <Tooltip
                  title={
                    devices && devices.offline.length !== 0
                      ? devices.offline.map((item) => `${item.name}\n`)
                      : "--"
                  }
                >
                  <Text type="secondary" strong>
                    {t("dashboard.card.offlineDevice")}
                  </Text>
                  <Statistic value={devices ? devices.offline.length : "--"} />
                </Tooltip>
              </Card>
            </Col>
            <Col className="gutter-row" span={24}>
              <Card
                className="lineStatisticCard1"
                title={t("dashboard.card.dataCollectTitle")}
                bordered={false}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", width: "50%", height: 300 }}>
                    <div id="databaseStoragePieChart" style={{ flex: 1 }} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "-100px",
                      width: "55%",
                      height: 300,
                    }}
                  >
                    <div id="databaseDtoBarChart" style={{ flex: 1 }} />
                  </div>
                </div>
              </Card>
            </Col>
            <Col className="gutter-row" span={24}>
              <Card
                className="statisticCard"
                title={t("dashboard.card.modelTitle")}
                bordered={false}
              >
                {aiModuleList.length === 0 ? (
                  <Spin
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10%",
                    }}
                  />
                ) : (
                  <p>TBD</p>
                )}
              </Card>
            </Col>
          </Row>
        </Col>
        <Col className="gutter-row" span={8}>
          <Card
            className="aksCard"
            title={t("dashboard.card.dataDiskTitle")}
            bordered={false}
          >
            {selectedAKS ? (
              <>
                <div style={{ marginBottom: "30px" }}>
                  <label style={{ marginRight: "10px" }}>
                    {t("label.clusterId")}:
                  </label>
                  <Select
                    defaultValue={selectedAKS.id}
                    // style={{ width: 120 }}
                    onChange={handleAKSChange}
                    options={aksInfo.map((item) => {
                      return {
                        value: item.id,
                        label: item.id,
                      };
                    })}
                  />
                  <label style={{ marginLeft: "100px", marginRight: "10px" }}>
                    {t("label.nrOfServices")}:
                  </label>
                  <label>{selectedAKS.nrOfServices}</label>
                </div>
                <div
                  style={{ display: "flex", width: "500px", height: "280px" }}
                >
                  <div id="cpuGaugeChart" style={{ flex: 1 }} />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "500px",
                    height: "280px",
                  }}
                >
                  <div id="ramGaugeChart" style={{ flex: 1 }} />
                </div>
              </>
            ) : (
              <Spin />
            )}
          </Card>
        </Col>
      </Row>
    </ConfigProvider>
  );
};

export default DashboardPage;
